export default function storyblokImageLoader({
  src,
  width,
  quality,
}: {
  src: string
  width: number
  quality: number
}) {
  // If the image is of type svg we don't want to perform any
  // optimizations.
  if (src.includes('.svg')) {
    return src
  }

  // This URL should fetch the image in an optimized webp format,
  // scaled proportional to its width.
  // See https://www.storyblok.com/docs/image-service#documentation
  // for more details.
  return `${src}/m/${width}x0/filters:quality(${quality || 100})`
}
