// This file is generated by npm run build:icons

export enum IconName {
  ArrowRight = "arrow-right",
  Bars = "bars",
  ChevronLeft = "chevron-left",
  ChevronRight = "chevron-right",
  ChevronUp = "chevron-up",
  Fueling = "fueling",
  Home = "home",
  InfoCircle = "info-circle",
  Loading = "loading",
  Lost = "lost",
  Minus = "minus",
  SignOut = "sign-out",
  SortDown = "sort-down",
  Times = "times",
  Tooltip = "tooltip",
}