import {signOut} from 'next-auth/react'
import {useTranslation} from 'next-i18next'
import analytics from 'analytics'
import {useCustomerAffiliateContext} from 'context/CustomerAffiliateProvider'
import {useRouter} from 'next/router'
import {getCallbackUrl} from 'utils/authentication'
import {FC} from 'react'
import Button from 'components/shared/Button'
import {IconName} from 'components/shared/icons/names'
import classNames from 'classnames'

type Props = {className?: string}

const LogoutItem: FC<Props> = ({className}) => {
  const {t} = useTranslation('common')
  const router = useRouter()
  const customerContext = useCustomerAffiliateContext()

  const handleSignOut = () => {
    analytics.sendUserLoggedOutEvent(
      'no',
      customerContext.currentUser?.digitalServiceUserId,
    )
    signOut({
      callbackUrl: getCallbackUrl(router),
    })
  }

  return (
    <Button
      className={classNames(className, 'py-3')}
      onClick={handleSignOut}
      label={t('log_out')}
      variant="secondary"
      icon={IconName.SignOut}
    />
  )
}

export default LogoutItem
