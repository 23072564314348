import {FC, type SVGProps} from 'react'
import {IconName} from './names'

type Props = SVGProps<SVGSVGElement> & {
  name: IconName
}

const Icon: FC<Props> = ({name, ...props}) => (
  <svg {...props}>
    <use href={`/sprite.svg#${name}`} />
  </svg>
)

export default Icon
