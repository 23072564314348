import {useRouter} from 'next/router'
import {useState} from 'react'
import {useTranslation} from 'next-i18next'
import {TranslatedSlug} from 'types/types'
import * as RSelect from '@radix-ui/react-select'
import Button from './shared/Button'
import {IconName} from './shared/icons/names'
import {getLanguageText} from 'utils/helpers'

const LanguageToggle = ({
  translatedSlugs,
}: {
  translatedSlugs: TranslatedSlug[] | undefined
}) => {
  const router = useRouter()
  const {i18n, t} = useTranslation('common')
  const locale = i18n?.language
  const [open, setOpen] = useState<boolean>(false)
  const [currentLocale, setCurrentLocale] = useState<string>(
    locale ? locale : 'nl',
  )

  const values = ['nl', 'fr']

  const changeLocale = (loc: string) => {
    setCurrentLocale(loc)

    // Find the corresponding translated slug for the new locale.
    const translatedSlug = translatedSlugs?.find(
      (slug: TranslatedSlug) => slug.lang === loc,
    )

    if (translatedSlug) {
      router.push(translatedSlug.path, translatedSlug.path, {locale: loc})
    }
  }

  return (
    <RSelect.Root
      onOpenChange={setOpen}
      open={open}
      onValueChange={value => changeLocale(value)}
    >
      <RSelect.Trigger>
        <Button
          label={getLanguageText(currentLocale, t) ?? ''}
          variant="secondaryOnColor"
          iconPosition="right"
          icon={IconName.SortDown}
          iconSize="sm"
        />
      </RSelect.Trigger>
      <RSelect.Portal>
        <RSelect.Content
          position="popper"
          sideOffset={0}
          className="SelectContent z-20 overflow-hidden rounded-dats border-2 border-dats-secondary-16 bg-dats-secondary-16"
        >
          <RSelect.Viewport>
            <RSelect.Group className="flex flex-col gap-[2px]">
              {values?.map(key => (
                <RSelect.Item
                  key={key}
                  className="cursor-pointer bg-dats-accent-1 p-4 duration-150 hover:bg-dats-secondary-16 data-[state=checked]:bg-dats-secondary-16 data-[state=checked]:font-bold"
                  value={key}
                >
                  <RSelect.ItemText>{getLanguageText(key, t)}</RSelect.ItemText>
                </RSelect.Item>
              ))}
            </RSelect.Group>
          </RSelect.Viewport>
        </RSelect.Content>
      </RSelect.Portal>
    </RSelect.Root>
  )
}

export default LanguageToggle
