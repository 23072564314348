import Image from 'next/image'
import Link from 'next/link'
import {MainMenuSlugAlternates} from 'mainMenuSlugTranslations'
import {IconName} from 'components/shared/icons/names'
import Icon from 'components/shared/icons/Icon'

interface MainMenuItemBlockParams {
  urlBlok: {url: string; cachedUrl: string}
  mainMenuSlugAlternates?: MainMenuSlugAlternates
  title?: string
  iconUrl?: string
  iconAlt?: string
  isSelected: boolean
}

const MainMenuItemBlock = ({
  urlBlok,
  mainMenuSlugAlternates,
  title,
  iconUrl,
  iconAlt,
  isSelected,
}: MainMenuItemBlockParams) => {
  let url = ''
  if (urlBlok) {
    if (urlBlok.url && urlBlok.url.length > 0) {
      url = urlBlok.url
    } else if (urlBlok.cachedUrl && urlBlok.cachedUrl.length > 0) {
      url = urlBlok.cachedUrl
    }
  }

  var localizedPath = url
  if (mainMenuSlugAlternates) {
    // Lookup the translated slug for the given url and point
    // directly to that one if found.
    const urlLookupKey = localizedPath.endsWith('/')
      ? `${localizedPath.split('/').join('_')}`
      : `${localizedPath.split('/').join('_')}_`

    const translation = mainMenuSlugAlternates[urlLookupKey]
    localizedPath = translation ?? url
  }

  return (
    <div className="md:px-4">
      <Link href={localizedPath}>
        <div className="flex h-14 w-full items-center justify-between md:h-12">
          <div className="flex items-center gap-2 md:gap-4">
            {iconUrl ? (
              <div className="size-4 justify-center">
                <Image
                  className="size-4"
                  src={iconUrl}
                  alt={iconAlt || ''}
                  width={24}
                  height={20}
                  unoptimized={true}
                />
              </div>
            ) : null}
            <div
              className={isSelected ? 'font-bold md:font-black' : 'font-bold'}
            >
              {title}
            </div>
          </div>
          <Icon
            name={IconName.ChevronRight}
            width={16}
            height={16}
            className="text-dats-secondary-3 md:hidden"
          />
        </div>
      </Link>
    </div>
  )
}

export default MainMenuItemBlock
