import MainMenuItemBlock from './MainMenuItem'
import LanguageToggle from '../../LanguageToggle'
import MainMenuHeader from 'components/Layout/Navigation/MainMenuHeader'
import {useRouter} from 'next/router'
import MainMenuDropDown from './MainMenuDropDown'
import LogoutItem from './LogoutItem'
import {TranslatedSlug} from '../../../types/types'
import Image from 'next/image'
import {MainMenuSlugAlternates} from 'mainMenuSlugTranslations'
import {useCustomerAffiliateContext} from 'context/CustomerAffiliateProvider'
import classNames from 'classnames'
import Link from 'next/link'
import {FC} from 'react'

export type MenuItem = {
  uuid: string
  iconSrc: string
  iconAlt: string
  url: string
  cachedUrl: string
  label: string
}

type MainMenuProps = {
  logoSrc: string | undefined
  menuItems: MenuItem[]
  mainMenuSlugAlternates: MainMenuSlugAlternates
  defaultSlug: string | undefined
  translatedSlugs: TranslatedSlug[] | undefined
  shouldCloseMenuCallback?: () => void
}

const MainMenu: FC<MainMenuProps> = ({
  logoSrc,
  menuItems,
  mainMenuSlugAlternates,
  defaultSlug,
  translatedSlugs,
  shouldCloseMenuCallback,
}) => {
  const router = useRouter()
  const customerContext = useCustomerAffiliateContext()

  const availableAffiliates =
    customerContext.availableUsers?.map(user => user.customerAffiliate.name) ??
    []

  const currentPath = router.locale
    ? router.locale + router.asPath
    : router.asPath

  // Checks if a given menu item should be selected.
  const isSelectedMenuItem = (cachedUrl: string | undefined) => {
    const sanitizedCurrentPath = currentPath
      .replace('nl/', '')
      .replace('fr/', '')

    if (sanitizedCurrentPath === '' && cachedUrl === '/home') {
      // We're looking at the root. Assume the dashboard is selected.
      return true
    }

    if (!translatedSlugs) {
      return false
    }

    let possiblePaths: string[] = translatedSlugs.map(slug => slug.path)
    if (defaultSlug) {
      possiblePaths.push(defaultSlug)
    }

    return !!possiblePaths.find(path => cachedUrl?.includes(path))
  }

  return (
    <div className="flex h-screen w-full flex-col bg-dats-accent-1 md:justify-between md:border-r-2 md:border-r-dats-secondary-13">
      <div>
        <div className="relative block md:hidden">
          <MainMenuHeader
            logoSrc={logoSrc}
            isMainMenuVisible={true}
            shouldCloseMenuCallback={shouldCloseMenuCallback}
          />
        </div>
        <div className="hidden p-6 md:flex md:items-center md:justify-center">
          {logoSrc && (
            <Link href="/">
              <Image
                src={logoSrc}
                alt=""
                width={96}
                height={32}
                unoptimized={true}
              />
            </Link>
          )}
        </div>
        {availableAffiliates.length > 1 ? (
          <div className="border-t-2 border-dats-secondary-13">
            <MainMenuDropDown
              shouldCloseMenuCallback={shouldCloseMenuCallback}
            />
          </div>
        ) : null}

        <ul className="border-t-2 border-t-dats-secondary-13 bg-dats-accent-1">
          {menuItems.map((item, idx) => (
            <li
              key={item.uuid}
              className={classNames(
                'mx-4 md:hover:border-l-dats-secondary-16 md:hover:bg-dats-secondary-16',
                'md:mx-0 md:border-l-4 md:border-l-dats-accent-1',
                {
                  'border-b border-b-dats-secondary-9 md:border-b-0':
                    idx !== menuItems.length - 1,
                  'md:!border-l-dats-primary-1 md:bg-dats-secondary-16':
                    isSelectedMenuItem(item.cachedUrl),
                },
              )}
            >
              <MainMenuItemBlock
                urlBlok={{
                  url: item.url,
                  cachedUrl: item.cachedUrl,
                }}
                mainMenuSlugAlternates={mainMenuSlugAlternates}
                title={item.label}
                iconUrl={item.iconSrc}
                iconAlt={item.iconAlt}
                isSelected={isSelectedMenuItem(item.cachedUrl)}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-between gap-2 p-4 pt-12 md:flex-col-reverse md:items-center md:pt-0">
        <LogoutItem className="md:w-full" />
        <LanguageToggle translatedSlugs={translatedSlugs} />
      </div>
    </div>
  )
}

export default MainMenu
