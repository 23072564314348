import {TFunction} from 'i18next'

export const getLanguageText = (key: string, t: TFunction) => {
  const values = [
    {key: 'nl', label: t('language_dutch')},
    {key: 'fr', label: t('language_french')},
  ]

  return values.find(x => x.key === key)?.label
}
