import React, {FC, PropsWithChildren} from 'react'
import Icon from './icons/Icon'
import {IconName} from './icons/names'
import classNames from 'classnames'
import Link from 'next/link'
import Image from 'next/image'
import {AssetStoryblok} from 'types/storyblok-types'

type Props = {
  label: string
  variant: Variant
  buttonType?: ButtonType
  submit?: boolean
  link?: string
  target?: Target
  loading?: boolean
  iconPosition?: IconPosition
  icon?: IconName
  icon_url?: string
  onClick?: Function
  disabled?: boolean
  className?: string
  iconSize?: IconSize
}

type IconSize = 'sm' | 'md'
type IconPosition = 'left' | 'right'
type Target = '_self' | '_blank'
type ButtonType = 'button' | 'link'

type Variant =
  | 'primary'
  | 'secondary'
  | 'secondaryOnColor'
  | 'tertiary'
  | 'floating'

const VARIANTS: Record<Variant, string> = {
  primary: 'bg-dats-secondary-3 text-dats-accent-1 hover:bg-dats-secondary-11',
  secondary:
    'border-2 bg-dats-accent-1 border-dats-secondary-3 hover:border-dats-secondary-11 text-dats-secondary-3 hover:text-dats-secondary-11',
  secondaryOnColor:
    'bg-dats-accent-1 text-dats-secondary-3 hover:text-dats-secondary-11',
  tertiary: 'text-dats-secondary-3 hover:text-dats-secondary-11 underline',
  floating:
    'bg-dats-accent-1 text-dats-secondary-3 hover:text-dats-secondary-11 shadow-dats-small',
}

const DISABLED_VARIANTS: Record<Variant, string> = {
  primary: 'bg-dats-secondary-13',
  secondary: 'border-2 bg-dats-accent-1 border-bg-dats-secondary-14',
  secondaryOnColor: 'bg-dats-accent-1',
  tertiary: 'underline',
  floating: 'bg-dats-accent-1 shadow-dats-small',
}

const ICON_SIZE: Record<IconSize, number> = {
  md: 16,
  sm: 8,
}

const Button: FC<PropsWithChildren<Props>> = ({
  children,
  label,
  variant,
  onClick,
  iconPosition,
  submit,
  link,
  loading,
  icon,
  icon_url,
  disabled,
  buttonType = 'button',
  target = '_self',
  iconSize = 'md',
  className,
}) => {
  const content = children ? (
    children
  ) : (
    <>
      {loading ? (
        <Icon
          name={IconName.Loading}
          className="animate-spin-slow"
          width={16}
          height={16}
        />
      ) : icon ? (
        <Icon
          name={icon}
          width={ICON_SIZE[iconSize]}
          height={ICON_SIZE[iconSize]}
        />
      ) : icon_url ? (
        <Image
          src={icon_url}
          width={ICON_SIZE[iconSize]}
          height={ICON_SIZE[iconSize]}
          className="pr-2"
          alt={`${label}-icon`}
          unoptimized={true}
        />
      ) : null}

      {label}
    </>
  )

  const classes = classNames(
    'p-4 rounded-dats overflow-hidden font-bold flex gap-2 items-center justify-center w-fit duration-150',
    {
      'flex-row-reverse': iconPosition === 'right',
      'pointer-events-none text-dats-secondary-12': disabled,
    },
    disabled ? DISABLED_VARIANTS[variant] : VARIANTS[variant],
    className,
  )

  return buttonType === 'link' ? (
    <Link
      href={link || '#'}
      onClick={e => {
        onClick?.(e)
      }}
      className={classes}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
    >
      {content}
    </Link>
  ) : (
    <button
      onClick={e => {
        onClick?.(e)
      }}
      className={classes}
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
    >
      {content}
    </button>
  )
}

export default Button
