import Image from 'next/image'
import React from 'react'
import {useTranslation} from 'next-i18next'
import Icon from 'components/shared/icons/Icon'
import {IconName} from 'components/shared/icons/names'
import Link from 'next/link'
import classNames from 'classnames'

type MainMenuHeader = {
  isMainMenuVisible: boolean
  logoSrc: string | undefined
  shouldOpenMenuCallback?: () => void
  shouldCloseMenuCallback?: () => void
}

const MainMenuHeader = ({
  logoSrc,
  isMainMenuVisible,
  shouldOpenMenuCallback,
  shouldCloseMenuCallback,
}: MainMenuHeader) => {
  const {t} = useTranslation('common')

  const openMenuButtonHandler = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    shouldOpenMenuCallback?.()
  }

  const closeMenuButtonHandler = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    shouldCloseMenuCallback?.()
  }

  return (
    <div
      className={classNames(
        'flex items-center justify-between bg-white px-6 py-4 shadow-dats-secondary-8 md:hidden',
        {'shadow-dats-menu': !isMainMenuVisible},
      )}
    >
      <div>
        {logoSrc && (
          <Link href="/">
            <Image
              src={logoSrc}
              alt=""
              width={100}
              height={33}
              unoptimized={true}
            />
          </Link>
        )}
      </div>
      {isMainMenuVisible ? (
        <button
          onClick={closeMenuButtonHandler}
          className="text-dats-secondary-3"
        >
          <Icon name={IconName.Times} width={16} height={16} />
        </button>
      ) : (
        <button onClick={openMenuButtonHandler}>
          <div className="flex items-center gap-2 text-dats-secondary-3">
            <span className="font-normal">{t('menu_header')}</span>
            <Icon name={IconName.Bars} width={24} height={24} />
          </div>
        </button>
      )}
    </div>
  )
}

export default MainMenuHeader
