import classNames from 'classnames'
import {FC, useEffect, useState} from 'react'
import {useCustomerAffiliateContext} from 'context/CustomerAffiliateProvider'
import * as RSelect from '@radix-ui/react-select'
import Icon from 'components/shared/icons/Icon'
import {IconName} from 'components/shared/icons/names'

type MainMenuDropDownProps = {
  shouldCloseMenuCallback?: () => void
}

const MainMenuDropDown: FC<MainMenuDropDownProps> = ({
  shouldCloseMenuCallback,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const customerContext = useCustomerAffiliateContext()

  const availableAffiliates = customerContext.availableUsers?.map(
    user => user.customerAffiliate.name,
  )

  const [selectedAffiliateName, setSelectedAffiliateName] = useState<
    string | undefined
  >()

  useEffect(() => {
    if (!selectedAffiliateName && availableAffiliates) {
      setSelectedAffiliateName(availableAffiliates[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelectedAffiliateName(customerContext.selectedAffiliateName)
  }, [customerContext.selectedAffiliateName])

  const updateSelectedAffiliate = (affiliateName: string) => {
    setSelectedAffiliateName(affiliateName)
    customerContext.setSelectedAffiliateName(affiliateName)

    var isSmallScreen = window.matchMedia
      ? window.matchMedia('screen and (max-width: 640px)')
      : screen.width <= 640

    if (isSmallScreen) {
      // Also hide the menu after affilicate selection when on the
      // mobile view.
      shouldCloseMenuCallback?.()
    }
  }

  return (
    <div className="relative">
      <RSelect.Root
        onOpenChange={setOpen}
        open={open}
        value={selectedAffiliateName}
        onValueChange={value => updateSelectedAffiliate(value)}
      >
        <RSelect.Trigger className="flex w-full items-center justify-between bg-dats-secondary-10 px-4 py-6 md:justify-start md:gap-4 md:p-4">
          <span className="truncate text-h3 md:text-p1">
            {selectedAffiliateName}
          </span>
          <Icon
            name={IconName.ChevronUp}
            width={16}
            height={16}
            className={classNames('shrink-0 text-dats-primary-1 duration-150', {
              'rotate-180': !open,
            })}
          />
        </RSelect.Trigger>
        <RSelect.Portal>
          <RSelect.Content
            position="popper"
            sideOffset={0}
            className="SelectContent z-20 -m-2 overflow-hidden rounded-dats border-2 border-dats-secondary-16 bg-dats-secondary-16 md:!w-80"
          >
            <RSelect.Viewport>
              <RSelect.Group className="flex flex-col gap-[2px]">
                {availableAffiliates?.map(value => (
                  <RSelect.Item
                    key={value}
                    className="cursor-pointer bg-dats-accent-1 p-4 duration-150 hover:bg-dats-secondary-16 data-[state=checked]:bg-dats-secondary-16 data-[state=checked]:font-bold"
                    value={value}
                  >
                    <RSelect.ItemText>{value}</RSelect.ItemText>
                  </RSelect.Item>
                ))}
              </RSelect.Group>
            </RSelect.Viewport>
          </RSelect.Content>
        </RSelect.Portal>
      </RSelect.Root>
    </div>
  )
}

export default MainMenuDropDown
